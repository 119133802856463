<template>
  <div class="Top">
    <v-card class="mx-auto" max-width="1170">
      <v-toolbar color="orange" dark>
        <v-toolbar-title>PC-COMBO.TOKYOへようこそ</v-toolbar-title>
      </v-toolbar>
      <p>本サイトは、自作PCパーツの関連情報や相性情報をまとめるポータルサイトです。</p>
      <v-list>
        <v-list-item-group>
          <v-list-item :to="{ name: 'About' }">
            <v-list-item-content>当サイトについて</v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'Category' }">
            <v-list-item-content>カテゴリ一覧から調べる</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-card class="mx-auto" max-width="1170">
      <v-toolbar color="orange" dark>
        <v-toolbar-title>最近登録した製品</v-toolbar-title>
      </v-toolbar>
      <v-list two-line>
        <v-list-item-group>
          <template v-for="newItem in newItems">
            <v-list-item :key="newItem.id" :to="{ name: 'Product', params: { productId: newItem.id } }">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-text="newItem.name"></v-list-item-title>
                  <v-list-item-subtitle class="text--secondary" v-text="newItem.brand.name"></v-list-item-subtitle>
                  <v-list-item-subtitle v-text="newItem.createdAt"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-spacer></v-spacer>
    <v-card class="mx-auto" max-width="1170">
      <v-toolbar color="orange" dark>
        <v-toolbar-title>関連情報を更新した製品</v-toolbar-title>
      </v-toolbar>
      <v-list two-line>
        <v-list-item-group>
          <template v-for="updatedItem in updatedItems">
            <v-list-item :key="updatedItem.id" :to="{ name: 'Product', params: { productId: updatedItem.id } }">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-text="updatedItem.name"></v-list-item-title>
                  <v-list-item-subtitle class="text--secondary" v-text="updatedItem.brand.name"></v-list-item-subtitle>
                  <v-list-item-subtitle v-text="updatedItem.updatedAt"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>


<script>
const axios = require('axios')

export default{
  name: 'Top',
  components: {
  },
  data () {
    return {
      newItems: null,
      updatedItems: null
    }
  },
  beforeCreate() {
    axios.get('https://api.pc-combo.tokyo/products/list/new.json')
        .then(response => (this.newItems = response.data.products))
        .catch(error => console.log(error))
    axios.get('https://api.pc-combo.tokyo/products/list/updated.json')
        .then(response => (this.updatedItems = response.data.products))
        .catch(error => console.log(error))
  }
}
</script>
